import axios from 'axios'
import router from '../router'
import 'firebase/auth'

const generateAPIEndPoint = () => {

    if (process.env.NODE_ENV === 'production') {
        return 'https://web.banktopup.com/api/v1'
    }
    return 'http://localhost:5000/api/v1'
}

export const API_ENDPOINT = generateAPIEndPoint()

const instance = axios.create({
    baseURL: API_ENDPOINT,
})
instance.interceptors.request.use(async (config) => {

    let token = localStorage.getItem('AccessToken')

    return {
        ...config,
        headers: {
            ...config.headers,
            ...(token && { Authorization: `Bearer ${token}` }),
        },
    }
})
instance.interceptors.response.use(
    (response) => {
        return response.data
    },
    async (err) => {
        if (err.response) {
            if (err.response.status === 401) {
                if (router.currentRoute.path !== '/auth/signin') {
                    await router.push('/auth/signout')
                }
            }
            return Promise.reject(err.response.data)
        }
        return Promise.reject({
            message: 'ไม่สามารถเชื่อมต่อเซิร์ฟเวอร์ได้ในขณะนี้',
        })
    }
)
export default instance